import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import { store } from "../../../store";
import "./styles/workshop.css";
import workshop from "./assets/workshop24.png";
import workshopResponsivo from "./assets/workshop24-responsivo.png";
const Course = observer((props) => {
  var forms = {
    "Dados pessoais": ["email", "name", "cpf", "postal_code", "cell_phone"],
    "Outras informações": ["utm", "utm_others"],
  };
  const prices =
    store.logged_in &&
    (store.ui.turma.course.includes("ACT") ||
      store.ui.turma.course.includes("FAP") ||
      store.ui.turma.course.includes("RFT"))
      ? {
          PIX: 190,
          "1x cartão": 190,
        }
      : {
          PIX: 380,
          "1x cartão": 380,
        };
  const course = "Workshop 24 Ceconte";
  const priceBRL = prices.PIX;
  return (
    <>
      <div className="center">
        <div className="m10 flex-center">
          <img className="imgWorkshop2" src={workshop} alt="" />
          <img
            className="imgWorkshop2Responsivo"
            src={workshopResponsivo}
            alt=""
          />
          <div className="align-left">
            <span className="descriptionWorkshop">
              <b>Horário: </b> 14h às 17h
              <br />
              <b>Investimento: </b> R$ {prices.PIX},00 <br />
              <b>Desconto de 50% para ex alunos </b> <br />
              <b>Nível de conhecimento: </b>intermediário
              <br />
            </span>
          </div>
        </div>
        <div className="alertWorkshop">
          <b className="b-alert">Atenção: </b>
          Por se tratar de um workshop, haverá atividades práticas que serão
          realizadas em pequenos grupos de até 5 pessoas. Por envolver a
          participação em vídeo e áudio dos inscritos durante esses momentos de
          prática, em subsalas do Zoom, esses trechos não serão gravados e não
          será possível participar como ouvinte desses momentos de interação,
          sendo permitida a permanência nas salas de prática (breakout rooms)
          somente das pessoas que efetivamente vão participar das atividades
          práticas.
          <br />
          <br />
          <b>
            Para obter o certificado de participação é necessário estar presente ao
            vivo em pelo menos 75% do tempo total do workshop.
          </b>
        </div>
        <hr />
        <div className="alertWorkshop">
          Nós vamos enviar o link de acesso para você participar do workshop no
          endereço de e-mail que você preencher no formulário de inscrição.
          Recomendamos que use o mesmo e-mail que usa para acessar o Zoom. Mas
          se você não tem uma conta no Zoom ainda, não se preocupe, depois que
          fizer a sua inscrição aqui, você receberá um link de acesso no e-mail
          e poderá criar a sua conta no Zoom por lá. Caso precise de suporte,
          envie um e-mail para <b>eventos@ceconte.com.br</b>.
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.history.push("/checkout-asaas", {
                items: [
                  {
                    description: `Inscrição - ${course}`,
                    quantity: 1,
                    price_cents: priceBRL * 100,
                  },
                ],
                course,
                semestre: "2024-2",
                forms,
                prices,
                turma: 441,
              });
            }}
          >
            Prosseguir
          </Button>
        </div>
      </div>
    </>
  );
});

export default Course;
